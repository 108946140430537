.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}
@media only screen and (max-width: 800px) {
  .App-header {
    font-size: calc(5px + 1vmin);
    width: 100%;
  }
}

.App-link {
  color: #61dafb;
}
.background{
  background-color: #282c34
}
@media only screen and (max-width: 800px) {
  .background {
    width: 100%;
    ;
  }
}
.cuadro{

  height:max-content;
  width: 350px;
  background-color: #282c34;
  padding-top: 8%;
  padding-bottom: 30px;
  margin: 5%;
}
@media only screen and (max-width: 800px) {
  .cuadro {
    width: 90%
  }
}
img{
  margin-left: auto;
  margin-right: auto;
  width: 450px;
}
.textocuadrado{
  width: 100%;
  height: 300px;
  font-size: medium;
  overflow: auto;
  margin-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
  color: white;
}
nav{
  background-color: #282c34;
  color: white;
  display: flex;
  justify-content: space-around;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 1.5rem;
}
button{
  background-color: #E0920F;
  border: 2px solid #E0920F;
  border-radius: 8px;
  width: 70%;
  color: white;
}
a{
  color: #E0920F;
}

button:hover{
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.btnficha{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

img:hover{
  animation: bounce 2s infinite;
  cursor: pointer;
}

@tailwind base;
@tailwind components;
@tailwind utilities;